<template>
    <div class="scrolling-modal">
        <header class="modal-header">
            <h2>{{ translate('Checkout', { value: activeModal.data.description }) }}</h2>
        </header>
        <content>
            <UIGridContainer>
                <UIGridRow class="payment">
                    <div v-for="item in items" :key="item.id" class="py-2 border-top d-flex w-100">
                        <UIImage
                            :width="34"
                            :height="24"
                            :src="$product.getImage340(item.product)"
                            :alt="$brand.getName(item.product.brand)"
                            :resource="true"
                        />
                        <span v-if="item.quantity > 1" class="ml-2">{{ item.quantity }}x</span>
                        <p class="ml-2 mb-0">{{ $brand.getName(item.product.brand) }}</p>
                        <span class="ml-auto d-flex align-items-center">{{
                            $formatCurrency($cart.getItemTotalPrice(item))
                        }}</span>
                        <div class="d-flex align-items-center" @click="removeFromCart(item)">
                            <UIIcon :name="['fal', 'times']" class="ml-2 deleteIcon"></UIIcon>
                        </div>
                    </div>
                </UIGridRow>
                <UIGridRow class="py-2 border-top">
                    <p class="mr-2 mb-0">{{ translate('cart.summary.transaction') }}</p>
                    <nuxt-resource
                        :key="activeModal.data.id"
                        :src="`images/payment/${activeModal.data.id}.svg`"
                        :alt="activeModal.data.description"
                        :height="25"
                        :width="25"
                    />
                    <span class="ml-auto">{{ $formatCurrency($cart.getTransactionCosts(activeModal.data)) }}</span>
                </UIGridRow>
                <UIGridRow class="total py-2 border-bottom">
                    <b>{{ translate('cart.summary.total') }}</b>
                    <b class="ml-auto">{{ $formatCurrency($cart.getCartTotal()) }}</b>
                </UIGridRow>
                <UIGridRow class="py-2 mt-5">
                    <p class="mb-0">
                        <span class="font-weight-bold">
                            {{
                                activeModal.data.id === 'paypal'
                                    ? translate('email.paypal')
                                    : translate('contact.email')
                            }}
                        </span>
                        <span class="required ml-1">({{ translate('required') }})</span>
                    </p>
                    <span class="small mb-2">{{
                        activeModal.data.id === 'paypal'
                            ? translate('delivery.paypal')
                            : translate('delivery.info.guest')
                    }}</span>
                    <form ref="emailForm" novalidate class="w-100">
                        <UIFormInput
                            v-model="email"
                            name="email"
                            type="email"
                            placeholder="enter your email"
                            required
                            validation="email"
                            @paste.native="pasteCheck()"
                        >
                            <template #error-message>
                                {{ translate('email.error') }}
                            </template>
                            <template #warning-message>
                                {{ translate('email.warning') }}
                            </template>
                        </UIFormInput>
                    </form>
                    <UIButton
                        ref="submit"
                        type="submit"
                        variant="primary"
                        class="w-100 mt-3"
                        @click.prevent.stop="onSubmit"
                        >{{ translate('checkoutButton') }}</UIButton
                    >
                </UIGridRow>
                <UIGridRow class="py-2">
                    <span class="small" v-html="translate('paymentWarning', { ...warningLinks })"></span>
                </UIGridRow>
            </UIGridContainer>
        </content>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UIGridRow, UIIcon, UIGridContainer, UIFormInput, UIButton, UIImage } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIGridRow,
        UIIcon,
        UIGridContainer,
        UIFormInput,
        UIButton,
        UIImage,
    },

    mixins: [TranslationMixin],

    data() {
        return {
            email: this.$auth.loggedIn && this.$auth.user ? this.$auth.user.email : '',
            name: this.$auth.loggedIn && this.$auth.user ? this.$auth.user.name : '',
            pasted: false,
        };
    },

    computed: {
        ...mapGetters({
            activeModal: 'ui/activeModal',
            items: 'cart/getItems',
        }),

        items() {
            const items = this.$store.state.cart.items;
            return items;
        },

        warningLinks() {
            return {
                warningLink: this.$locale.path('legal-disclaimer'),
                termsLink: this.$locale.path('legal-terms-and-conditions'),
                privacyLink: this.$locale.path('legal-privacy'),
                returnLink: this.$locale.path('legal-return-policy'),
                complaintLink: this.$locale.path('legal-complaint-policy'),
                buttonName: this.translate('checkoutButton'),
            };
        },
    },

    methods: {
        ...mapActions({
            removeFromCart: 'cart/removeItem',
            showModal: 'ui/showModal',
            setName: 'user/setName',
            setEmail: 'user/setEmail',
        }),

        pasteCheck() {
            this.pasted = true;
        },

        async onSubmit(ev) {
            if (this.$refs.submit) {
                this.$refs.submit.startLoading();
            }

            if (!this.$validation.validateForm(this.$refs.emailForm)) return false;

            return await this.$user
                .login(this.$auth.strategy.name, this.email)
                .then(() => {
                    if (this.$auth.loggedIn) {
                        this.setName(this.$auth.user.name);
                        this.setEmail(this.$auth.user.email);
                    }
                })
                .then(() => {
                    // Check user security call:
                    return this.$api.actions
                        .checkUser(this.$auth.user.name, this.$auth.user.email, false)
                        .catch(() => {});
                })
                .then(() => {
                    this.$gtm?.push({
                        event: 'event_product_page_button_quick_checkout_finish',
                        value: this.items[0].productId,
                    });

                    return this.$cart.checkout(this.activeModal.data.id, {
                        quickCheckout: true,
                    });
                })
                .catch(e => {
                    console.error(e);

                    if (this.$refs.submit) {
                        this.$refs.submit.stopLoading();
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-header {
    ::v-deep h2 {
        margin: 2rem 1rem 1rem 1rem;
    }
}
.total {
    border-top: 2px solid $color-grey-3;
}
.productImage {
    max-width: 2.2rem;
}
.deleteIcon {
    &:hover {
        cursor: pointer;
    }
}
.payment {
    max-height: 6.7rem;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
        border-radius: 3.5px;
        background-color: $color-form-bg;
    }

    &::-webkit-scrollbar {
        width: 7px;
        border-radius: 4px;
        background-color: $color-form-bg;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        background-color: $color-grey-3;
    }
}
</style>

<i18n src="@dundle/locale/data/cart.json"></i18n>
<i18n src="@dundle/locale/data/keywords.json"></i18n>
<i18n src="@dundle/locale/data/validation.json"></i18n>
<i18n></i18n>
